import { defineComponent, ref, computed } from 'vue'
import WdHeader from '@/components/WdHeader/WdHeader.vue'
import PortalLeftList from './component/portalLeftList.vue'
import PortalRightContent from './component/portalRightContent.vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import { getAppList } from '@/plugins/setupMicro'
import { message, Modal } from 'ant-design-vue'
import { getOtherTokens } from '@/service/loginService'
import { getAuthMenuList, getCisAuthMenuList } from '@/service/userService'
export default defineComponent({
    name: 'Home',
    components: {
        WdHeader,
        Modal,
        PortalLeftList,
        PortalRightContent
    },
    setup () {
        message.config({
            maxCount: 1
        })
        const router = useRouter() // 路由
        const userData = computed(() => useUserStore().getUserInfo) // 取用户数据
        const jobNo = computed(() => {
            if (userData.value) {
                return userData.value.jobNo
            }
            return ''
        })
        // 取用户PC端的应用权限
        const appList = computed(() => {
            if (userData.value) {
                return userData.value.applicationList.filter(
                    (item) => item.platform === 'pc'
                )
            }
            return []
        })
        // 取用户有权限的PC端应用name
        const appNameList: string[] = []
        appList.value.forEach(item => {
            appNameList.push(item.appId)
        })
        // 本地应用列表 - 市场咨询
        const appMarketListLocal = ref<any>([
            {
                appId: 'marketing',
                applicationName: '营销系统',
                appDescribe: '市场活动，校园大使管理系统',
                iconPath: 'market_marketing',
                routerType: 'router',
                hasAuth: false,
                hasSubBtn: true,
                subBtnList: [
                    {
                        btnName: '添加大使',
                        btnLink: 'campusAgentManage/agentList?source=main&type=addAgent',
                        authType: 'btn',
                        authCode: 'agent:add'
                    },
                    {
                        btnName: '分配大使',
                        btnLink: 'campusAgentManage/noDistribution?source=main',
                        authType: 'menu',
                        authCode: ['agentNoOrg', 'agentNoCampus', 'agentNoConsultant']
                    },
                    {
                        btnName: '我的代理',
                        btnLink: 'campusAgentManage/agentList?source=main',
                        authType: 'menu',
                        authCode: 'agentList'
                    }
                ]
            },
            {
                appId: 'consulthelper',
                applicationName: '咨询师助手',
                appDescribe: '咨询资料汇总',
                iconPath: 'market_consulthelper',
                routerType: '_blank',
                hasAuth: false,
                hasSubBtn: true,
                subBtnList: [
                    { btnName: '院校库', btnLink: 'info/college?source=main' },
                    { btnName: '课程产品', btnLink: 'product/course?source=main' },
                    { btnName: '试听课', btnLink: 'product/listenCourse?source=main' }
                ]
            },
            {
                appId: 'consultsys',
                applicationName: '咨询师助手后台',
                appDescribe: '咨询资料数据维护',
                iconPath: 'market_consultsys',
                routerType: 'router',
                hasAuth: false
            },
            {
                appId: 'consult',
                applicationName: '线上资源',
                appDescribe: '渠道、线索管理配置',
                iconPath: 'market_consult',
                routerType: 'router',
                hasAuth: false
            }
        ])
        // 本地应用列表 - 销售转化
        const appSaleListLocal = ref<any>([
            {
                appId: 'student',
                applicationName: '线索管理',
                appDescribe: '意向信息跟进转化',
                iconPath: 'sale_student',
                routerType: '_blank',
                hasAuth: false,
                hasSubBtn: true,
                subBtnList: [
                    {
                        btnName: '添加线索',
                        btnLink: 'studentClue/studentClue?source=main&type=addStudent',
                        authType: 'btn',
                        authCode: 'studentClue:allStudent:add'
                    },
                    {
                        btnName: '下单',
                        btnLink: 'studentClue/studentClue?source=main&type=findStuOrderFun',
                        authType: 'btn',
                        authCode: 'studentClue:allStudent:order'
                    },
                    {
                        btnName: '查找学员',
                        btnLink: 'findStudentClue/findStudent?source=main',
                        authType: 'menu',
                        authCode: 'find_student_clue_find'
                    }
                ]
            },
            {
                appId: 'product',
                applicationName: '产品管理',
                appDescribe: '课程、产品信息维护',
                iconPath: 'sale_product',
                routerType: '_blank',
                hasAuth: false,
                hasSubBtn: true,
                subBtnList: [
                    {
                        btnName: '套餐管理',
                        btnLink: 'product/meal?source=main&type=productMeal',
                        authType: 'menu',
                        authCode: 'meal'
                    },
                    {
                        btnName: '订单中心',
                        btnLink: 'courseChoose/orderList?source=main&type=orderList',
                        authType: 'menu',
                        authCode: 'order'
                    }
                ],
                appType: 'cis',
                appIdNumber: '500050615'
            }
        ])
        // 本地应用列表 - 教学服务
        const appTechListLocal = ref<any>([
            {
                appId: 'educational',
                applicationName: '教务管理',
                appDescribe: '学员转让、退费，财务课消确认',
                iconPath: 'tech_educational',
                routerType: '_blank',
                hasAuth: false,
                hasSubBtn: true,
                subBtnList: [
                    {
                        btnName: '学员列表',
                        btnLink: 'students/studentList?source=main',
                        authType: 'menu',
                        authCode: 'studentList'
                    },
                    {
                        btnName: '退费记录',
                        btnLink: 'consumption/refundList?source=main',
                        authType: 'menu',
                        authCode: 'refundList'
                    }
                ],
                appType: 'cis',
                appIdNumber: '500042121'
            },
            {
                appId: 'eduadm',
                applicationName: '新教务系统',
                appDescribe: '学员管理，排课系统',
                iconPath: 'tech_eduadm',
                routerType: '_blank',
                hasAuth: false,
                hasSubBtn: true,
                subBtnList: [
                    {
                        btnName: '学员列表',
                        btnLink: 'student/list?source=main',
                        authType: 'menu',
                        authCode: 'edu_student_list'
                    },
                    {
                        btnName: '校区课表',
                        btnLink: 'schedule/school?source=main',
                        authType: 'menu',
                        authCode: 'edu_schedule_school'
                    },
                    {
                        btnName: '学管师课表',
                        btnLink: 'schedule/academic?source=main',
                        authType: 'menu',
                        authCode: 'edu_schedule_academic'
                    }
                ]
            }
        ])
        // 本地应用列表 - 管理工具
        const appToolListLocal = ref<any>([
            {
                appId: 'auth',
                applicationName: '系统管理',
                appDescribe: '系统基础设置',
                iconPath: 'tool_auth',
                routerType: 'router',
                hasAuth: false,
                hasSubBtn: false
            },
            {
                appId: 'statistics',
                applicationName: '数据统计',
                appDescribe: '渠道数据统计、漏斗分析',
                iconPath: 'tool_statistics',
                routerType: 'router',
                hasAuth: false,
                hasSubBtn: false
            },
            {
                appId: 'contract',
                applicationName: '电子合同',
                appDescribe: '协议模版管理，签署设置',
                iconPath: 'tool_contract',
                routerType: 'router',
                hasAuth: false,
                hasSubBtn: false
            }
        ])
        // 本地所有app数据集合
        const appTotalListLocal = ref([...appMarketListLocal.value, ...appSaleListLocal.value, ...appTechListLocal.value, ...appToolListLocal.value])
        // 判断是否有权限
        appTotalListLocal.value.forEach((item: any) => {
            if (appNameList.indexOf(item.appId) !== -1) {
                item.hasAuth = true
            }
        })
        /*
            一、跳转应用及应用内子页面/子模块基础逻辑：
            1. 先判断是否有权限，无权限给提示
            2. 判断跳转类型routerType，为router时项目内通过路由跳转
            3. 如果是跳应用内子页面/子模块，需要拼接对应的参数
            4. routerType为_blank时，新窗口跳转
            5. 判断是否非CRM3.0应用
            6. 当应用为CIS产品管理、CIS教务等项目时，跳转之前先获取并存储对应的token，再进行跳转

            二、需注意的点：
            1. 跳转到应用内的子页面/子模块涉及到弹窗等功能类的交互，需在对应项目的对应页面/模块内根据传参进行特殊处理
            2. 获取非CRM3.0应用的token时，需调用后端提供的接口并传递参数，参数含义为：
               jobNo：当前用户工号
               appIds：要跳转的应用id（如CIS产品管理为500050615）
               project：固定值'product'（只有CIS产品管理项目需传递此参数，其他项目不用）
        */
        const jumpToApp = async (item: any, itemSub: any) => {
            if (!item.hasAuth) {
                message.warning('您无权查看，请找管理员开通权限')
            } else { // 打开新页面跳转
                if (item.appType && item.appType === 'cis') { // cis产品管理、cis教务
                    const params = {
                        jobNo: jobNo.value,
                        appIds: [item.appIdNumber],
                        project: item.appId === 'product' ? 'product' : ''
                    }
                    const tokenResult = await getOtherTokens(params)
                    if (tokenResult) {
                        if (item.appId === 'product') {
                            useUserStore().setCisProductToken(tokenResult.result[0].token)
                        } else if (item.appId === 'educational') {
                            useUserStore().setCisJwToken(tokenResult.result[0].token)
                        }
                        if (itemSub) {
                            const cisMenuResult = await getCisAuthMenuList({ appId: item.appIdNumber })
                            if (cisMenuResult && cisMenuResult[0].children && cisMenuResult[0].children.length > 0) {
                                if (itemSub.authType === 'menu') {
                                    const menuList = []
                                    for (let i = 0; i < cisMenuResult[0].children.length; i++) {
                                        menuList.push(...cisMenuResult[0].children[i].children)
                                    }
                                    const isHasAuth = await checkMenuAuthCis(menuList, itemSub.authCode)
                                    if (!isHasAuth) {
                                        message.warning('您无权查看，请找管理员开通权限')
                                        return false
                                    }
                                }
                            } else {
                                message.warning('您无权查看，请找管理员开通权限')
                                return false
                            }
                        }
                        const app = getAppList().find((i: any) => i.name === item.appId)
                        const url = itemSub ? app.entry + itemSub.btnLink : app.entry
                        window.open(url, '_blank')
                    }
                } else { // CRM项目
                    if (itemSub && item.appId !== 'consulthelper') {
                        const result = await getAuthMenuList({ appId: item.appId })
                        if (result && ((result.menuList && result.menuList.length > 0) || (result.resourceGroupList && result.resourceGroupList.length > 0))) {
                            if (itemSub.authType === 'menu') {
                                const isHasAuth = await checkMenuAuth(result, itemSub.authCode)
                                if (!isHasAuth) {
                                    message.warning('您无权查看，请找管理员开通权限')
                                    return false
                                }
                            } else if (itemSub.authType === 'btn') {
                                const isHasAuth = await checkAuth(result, itemSub.authCode)
                                if (!isHasAuth) {
                                    message.warning('您无权查看，请找管理员开通权限')
                                    return false
                                }
                            }
                        } else {
                            message.warning('您无权查看，请找管理员开通权限')
                            return false
                        }
                    }
                    if (item.routerType === 'router') { // 项目内路由跳转
                        const path = itemSub ? `/${item.appId}/${itemSub.btnLink}` : `/${item.appId}/`
                        router.push(path)
                    } else if (item.routerType === '_blank') {
                        const app = getAppList().find((i: any) => i.name === item.appId)
                        const url = itemSub ? app.entry + itemSub.btnLink : app.entry
                        window.open(url, '_blank')
                    }
                }
            }
        }
        // 检查是否有菜单权限 - cis老项目
        const checkMenuAuthCis = (list: any, code: string | string[]| boolean) => {
            if (list && list.length > 0) {
                if (code && typeof code === 'string') {
                    return list.some((item: any) => item.code === code)
                }
                if (code && code instanceof Array) {
                    return list.some((item: any) => {
                        return code.some((sitem: string) => sitem === item.code)
                    })
                } else if (code === true) {
                    return true
                }
            }
            return false
        }
        // 检查是否有菜单权限
        const checkMenuAuth = (list: any, code: string | string[]| boolean) => {
            if (list && list.menuList && list.menuList.length > 0) {
                if (code && typeof code === 'string') {
                    return list.menuList.some((item: any) => item.menuCode === code)
                }
                if (code && code instanceof Array) {
                    return list.menuList.some((item: any) => {
                        return code.some((sitem: string) => sitem === item.menuCode)
                    })
                } else if (code === true) {
                    return true
                }
            }
            return false
        }
        // 检查是否有资源组权限
        const checkAuth = (list: any, code: string | string[]| boolean) => {
            if (list && list.resourceGroupList && list.resourceGroupList.length > 0) {
                if (code && typeof code === 'string') {
                    return list.resourceGroupList.some((item: any) => item.resourceGroupCode === code)
                }
                if (code && code instanceof Array) {
                    return list.resourceGroupList.some((item: any) => {
                        return code.some((sitem: string) => sitem === item.resourceGroupCode)
                    })
                } else if (code === true) {
                    return true
                }
            }
            return false
        }
        return {
            appList,
            appMarketListLocal,
            appSaleListLocal,
            appTechListLocal,
            appToolListLocal,
            appTotalListLocal,
            jumpToApp
        }
    }
})
