
import { defineComponent, PropType } from 'vue'
export default defineComponent({
    name: 'portalLeftList',
    props: {
        appListLocal: {
            type: Array as PropType<any>,
            required: true
        }
    },
    setup (props, ctx) {
        const jumpToAppAndSub = (item: any, itemSub: any) => {
            ctx.emit('jumpToApp', item, itemSub)
        }
        return {
            jumpToAppAndSub
        }
    }
})
