
import { defineComponent, ref } from 'vue'
export default defineComponent({
    name: 'portalRightContent',
    setup () {
        // 公告弹窗是否显示
        const isNoticeModalShow = ref(false)
        // 打开公告弹窗
        const openNoticeModal = () => {
            isNoticeModalShow.value = true
        }
        return {
            isNoticeModalShow,
            openNoticeModal
        }
    }
})
